export const environment = {
    production: false,
    apiURL: 'https://devservices.sequation.net',
    reportsBiUrl:
    `https://pefreports.azurewebsites.net/api/reportEmbedToken?code=TEA3Dp25kgc6WkZKoeRXvf4sUjkLEmjKri8oBxeHbcGJMMz9aJeAwg==`,
    logo: `./assets/logo/sequation_logo.png`,
    appTitle: 'SEQUATION',
    appInsights: {
        instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b',
        disableExceptionTracking: true
    },
    azureBlobStorage: 'https://sequationdevsa.blob.core.windows.net'
};

