import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';

interface IRoutePart {
  title: string;
  breadcrumb: string;
  params?: Params;
  queryParams?: Params;
  url: string;
  urlSegments: any[];
  preserveParam?: string;
}

@Injectable()
export class RoutePartsService implements OnInit {

  public routeParts: IRoutePart[];

  constructor() {}

  ngOnInit() {
  }

  generateRouteParts(snapshot: ActivatedRouteSnapshot): IRoutePart[] {
    let routeParts = [] as IRoutePart[];

    if (snapshot) {
      if (snapshot.firstChild) {
        routeParts = routeParts.concat(this.generateRouteParts(snapshot.firstChild));
      }
      if (snapshot.data['title'] && snapshot.url.length) {
        routeParts.push({
          title: snapshot.data['title'],
          breadcrumb: snapshot.data['title'],
          url: snapshot.url[0].path,
          urlSegments: snapshot.url,
          params: snapshot.params,
          queryParams: snapshot.queryParams,
          preserveParam: snapshot.data['preserveParam']
        });
      }
    }
    return routeParts;
  }
}
