import * as moment from 'moment';
import { ApplicationConstant } from '../constants/app-constant';

export class Utility {
    static filterArrayByKeyAndValue(items: any[], key: any, value: any): any[] {
        return items.filter(function (item) {
            return item[key] === value;
        });
    }

    static extractValueFromArrayByKey(items: any[], key: any) {
        const list = [];
        items.forEach(element => {
            list.push(element[key]);
        });
        return list;
    }

    static getObjectFromArrayByKeyAndValue(items: any[], key: any, value: any): any {
      const list = items.filter(function (item) {
          return item[key] === value;
      });
      if (list.length === 1) {
          return list[0];
      }
      if (list.length <= 0) {
          return null;
      }
      if (list.length > 1) {
          throw new Error('The specified array has multiple objects with key: ' + key + ' and value: ' + value);
      }
      return null;
    }
    static transfromDateToString(date) {
        if (date) {
          return new Date(date).getFullYear() + '-' + ('0' + (new Date(date).getMonth() + 1)).slice(-2) +
          '-' + ('0' + new Date(date).getDate()).slice(-2);
        } else {
          return date;
        }
      }

    static transformStringToMomentDate(date: string) {
      moment.locale('en-gb');
      return moment(date, ApplicationConstant.DEFAULT_DATE_FORMAT);
  }

  static groupBy(array: any[], key: any) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  static getUniqueObjectsByProperties(arr, keyProps) {
    return Object.values(arr.reduce((uniqueMap, entry) => {
      const key = keyProps.map(k => entry[k]).join('|');
      if (!(key in uniqueMap)) {
          uniqueMap[key] = entry;
      }
      return uniqueMap;
    }, {}));
  }

  static isEmpty(value: string): boolean {
    if (value === undefined || value === null || value === '') {
        return true;
    }
    return false;
  }

    static transformMomentDateToString(date) {
      if (date && moment.isMoment(date)) {
          return date.format(ApplicationConstant.DEFAULT_DATE_FORMAT);
      } else if (date && !moment.isMoment(date)) {
          return this.transformStringToMomentDate(date).format(ApplicationConstant.DEFAULT_DATE_FORMAT);
      } else {
          return date;
      }
  }
}
