import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

    snackBarConfig: MatSnackBarConfig;
    snackBarRef: MatSnackBarRef<any>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    snackBarAutoHide = 6000;

    constructor(private readonly snackBar: MatSnackBar) { }

    topSnackBar(message) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = +this.snackBarAutoHide;
        this.snackBarConfig.panelClass = 'top-snackbar';
        this.snackBarRef = this.snackBar.open(message, '✖', this.snackBarConfig);
    }

    success(message) {
        let snackMessage: string;
        message ? snackMessage = message : snackMessage = 'Success!';
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.duration = +this.snackBarAutoHide;
        this.snackBarConfig.panelClass = 'success-snackbar';
        this.snackBarRef = this.snackBar.open(snackMessage, '✖', this.snackBarConfig);
    }

    error(error) {
        let snackError: string;

        error ? snackError = error : snackError = 'Server side error!';
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.duration = +this.snackBarAutoHide;
        this.snackBarConfig.panelClass = 'error-snackbar';
        this.snackBarRef = this.snackBar.open(snackError, '✖', this.snackBarConfig);
    }

}
