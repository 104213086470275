import { environment } from '../../../environments/environment';

export const BaseUrl = {
    AUTHENTICATE: environment.apiURL + '/sequation-authentication-api',
    USER_VALIDATION: environment.apiURL + '/user-validation-api',
    ASSESSMENT :  environment.apiURL + '/sequation-assessment-api',
    ORGANIZATION: environment.apiURL + '/sequation-organization-api',
    USER: environment.apiURL + '/sequation-usermanagement-composer-api',
    MOJ_MIS_PEF: environment.apiURL+ '/sequation-moj-pef-contract-api',
    USER_MANAGEMENT: environment.apiURL + '/sequation-user-management-api',
    AUTHORIZATION: environment.apiURL + '/sequation-authorization-api',
    CONTENT_MANAGEMENT: environment.apiURL + '/sequation-content-management-api',
    WORKSHOP_MANAGEMENT: environment.apiURL + '/sequation-moj-pef-workshop-api',
    REPORTS_BI: environment.reportsBiUrl,
    LOGO: environment.logo,
    APPTITLE: environment.appTitle,
};
