import { AppInsightsService } from './app-insights.service';
import { AppService } from './shared/services/app.service';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { BrandingService } from './features/app-settings/branding/branding.service';
import { RoutePartsService } from './shared/services/route-parts.service';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { SessionsService } from './sessions/sessions.service';
import { UserActivityService } from './sessions/user-activity/user-activity.service';
import { UserActivityModule } from './sessions/user-activity/user-activity.module';
import { AuthorizationGuard } from './shared/guards/authorization.guard';
import { HttpRequestInterceptorModule } from './shared/services/request-interceptor.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CanDeactivateGuard } from './shared/guards/can-deactivate/can-deactivate.guard';
import { SnackBarService } from './shared/services/snack-bar.service';
import { InPageNavService } from './shared/components/in-page-nav/in-page-nav.service';
import { AppDateAdapter, APP_DATE_FORMATS } from './shared/components/date-adapter/date-adapter';

export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MatSnackBarModule,
        NoopAnimationsModule,
        HttpRequestInterceptorModule,
        MatNativeDateModule,
        NgHttpLoaderModule.forRoot(),
        MatDialogModule,
        UserActivityModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        HammerModule
    ],
    providers: [
        BrandingService,
        RoutePartsService,
        AuthenticationGuard,
        AuthorizationGuard,
        CanDeactivateGuard,
        SessionsService,
        UserActivityService,
        SnackBarService,
        AppInsightsService,
        AppService,
        InPageNavService,
        { provide: LOCALE_ID, useValue: 'en-GB' },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(overlayContainer: OverlayContainer) {
        overlayContainer.getContainerElement().classList.add('seq-theme');
      }
}
