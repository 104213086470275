import { Injectable, NgModule } from '@angular/core';
import {
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { SessionsService } from '../../sessions/sessions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidatorService } from '../components/form-control-messages/validator.service';

@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        public sessionsService: SessionsService,
        public router: Router,
        private readonly snack: MatSnackBar,
        private readonly validatorService: ValidatorService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');

        request = request.clone({
            headers: request.headers.set('X-Authorization', 'Bearer ' + token)
                // .set('Content-Type', 'application/json')
        });
        return next.handle(request).pipe(
            tap(
                (response: HttpEvent<any>) => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.sessionsService.signout().subscribe();
                            this.snack.open(err.error.errorMessage || err.message || 'UnAuthorized User', 'Dismiss', { duration: 6000 });
                        }
                        if (err.status === 500) {
                            this.snack.open(err.error.errorMessage || err.message, 'Dismiss', { duration: 6000 });
                        }
                        if (err.status === 422) {
                          this.validatorService.setformSererValidator(err.error.errors);
                      }
                    }
                }
            )
        );
    }
}

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        }
    ]
})
export class HttpRequestInterceptorModule { }
